.grid2 {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

@media (min-width: 700px) {
  .grid2 {
    grid-template-columns: 1fr 1fr;
  }
}
