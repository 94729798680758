.Slider {
  margin-bottom: 50px;
}

.splide__pagination__page.is-active {
  background: #99d72e;
}

.splide__pagination__page:hover {
  background: #99d72e;
  cursor: pointer;
  opacity: 0.9;
}

.splide__arrow svg {
  fill: #99d72e;
}

.splide__arrow:hover svg {
  fill: #b0e15c;
}

.Slider__image {
  max-width: 100%;
}
.Slider__Splide {
  padding: 0 50px;
}

.splide__pagination {
  bottom: -30px;
}
