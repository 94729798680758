.icon{
    box-shadow: #1b75bb4D 0px 0px 10px;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    text-decoration: none;
}
.icon span{
    margin-top: 1rem;
}
.icon{
    color: #1b75bb;
    text-align: center;
}