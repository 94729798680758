.Advertence {
  background-color: #1b75bb;
  padding: 1rem;
  color: white;
  border-radius: 1rem;
  margin: 1rem auto;
  display: flex;
  gap: 0.5rem;
}
.Advertence img {
  height: 50px;
  width: auto;
  margin-top: 1rem;
}
