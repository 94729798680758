.Notas {
    background-color: #1b75bb;
    padding: 1rem;
    color: white;
    text-align: center;
    border-radius: 1rem;
    margin: 1rem auto;
    position: relative;
}

.Notas::before {
    content: '”';
    display: block;
    font-size: 2rem;
    text-align: left;
}

.Notas::after {
    content: '”';
    display: block;
    font-size: 2rem;
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 1rem;
}

.Notas--green {
    background-color: #9ad82e;
    color: #1b75bb;
}

.Notas--white {
    background-color: #f8f8f8;
    color: #1b75bb;
}
