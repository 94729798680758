.Nosotros {
  max-width: 1200px;
  margin: 1rem auto;
  position: relative;
  color: #92a3b0;
}

.Nosotros h3 {
  color: #1b75bb;
}

.Nosotros__titulo {
  background-color: #1b75bb;
  color: white;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  gap: 2rem;
  max-width: 1200px;
  margin: 1rem auto;
  text-decoration: none;
}
.Nosotros__titulo img {
  width: 50px;
}

.Nosotros__buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.Nosotros__image {
  margin: 15px auto;
  max-width: 100%;
  border-radius: 1rem;
  display: block;
}

.Nosotros__buttons a {
  text-decoration: none;
  border-radius: 1rem;
  background-color: #9ad82e;
  padding: 0.5rem 1rem;
  color: white;
}
