.Inicio {
  display: block;
  margin: 0 auto;
  max-width: 1200px;
}
.Inicio__cards {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  margin-bottom: 1rem;
}

.Inicio h2 {
  color: #1b75bb;
}

@media (min-width: 500px) {
  .Inicio__cards {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 800px) {
  .Inicio__cards {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
