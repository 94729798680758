footer {
  background-color: #1b75bb;
  max-width: 1200px;
  margin: 1rem auto;
  border-radius: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  color: white;
  padding: 1rem;
  gap: 1rem;
  box-sizing: border-box;
}
footer div {
  display: flex;
  gap: 0.5rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
footer img {
  display: block;
  width: 50px;
}

footer h4 {
  margin: 0;
}

footer a {
  color: #fff;
  font-weight: 700;
  line-height: 1.5;
}

footer span.phone {
  padding: 0.5rem;
  padding-right: 2.5rem;
  /* border-radius: 0 1rem 1rem 0; */
  border-right: solid 2px white;
  /* border-top: solid 2px white;
    border-bottom: solid 2px white; */
}

@media (min-width: 768px) {
  footer {
    grid-template-columns: 4fr 4fr;
  }
}

@media (min-width: 992px) {
  footer {
    grid-template-columns: 2fr 2fr 2fr;
  }
  footer div:last-child {
    grid-column-start: 2;
  }
}
@media (min-width: 1300px) {
  footer {
    grid-template-columns: 3fr 3fr 2fr 2fr;
  }
  footer div:last-child {
    grid-column-start: auto;
  }
}
