.Card h2 {
  color: #1b75ba;
}

.Card__contenedor {
  display: flex;
  justify-content: space-around;
}

.Card__card {
  background-color: #f8f8f8;
  border-radius: 1rem;
  padding: 1rem;
}
.Card__header {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 1rem;
  position: relative;
}
.Card__header img {
  width: 100%;
  height: auto;
}
.Card__titulos h3 {
  margin: 0;
  color: #1b75ba;
}
.Card__titulos h4 {
  margin: 0;
  font-size: 0.9rem;
  font-weight: 400;
  color: #1b75ba;
}
.Card__body {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.Card__body p {
  color: #91a2af;
}

.Card__link {
  color: white;
  background: #9ad82e;
  display: inline-block;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  text-decoration: none;
  font-size: 1rem;
}
