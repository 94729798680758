.Download {
  background-color: #1b75bb;
  padding: 1rem;
  color: white;
  border-radius: 1rem;
  margin: 1rem auto;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  font-size: 1.2rem;
}

.Download a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.Download img {
  height: 50px;
  width: auto;
  margin-top: 1rem;
}
